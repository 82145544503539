
import Vue from "vue";
import {Team} from "../../cs6131-backend/types/teamTypes";
import {BasicUser} from "../../cs6131-backend/types/userTypes";
import {onLogin} from "@/api/userApi";
import {AlertData} from "@/schemas/alertData";
import * as teamApi from "@/api/teamApi";
import TeamCTFs from "@/components/Team/TeamCTFs.vue";
import TeamMembers from "@/components/Team/TeamMembers.vue";
import TeamParticipate from "@/components/Team/TeamParticipate.vue";
import OwnerLeaveDialog from "@/components/Dialogs/OwnerLeaveDialog.vue";
import GenericWarningDialog from "@/components/Dialogs/GenericWarningDialog.vue";

export default Vue.extend({
  name: "Team",
  components: {
    GenericWarningDialog,
    'TeamCTFs': TeamCTFs,
    'TeamMembers': TeamMembers,
    'TeamParticipate': TeamParticipate,
    OwnerLeaveDialog
  },
  data() {
    return {
      team: new Team(),
      user: new BasicUser(),
      members: [] as Array<string>,
      loading: true,
      toolbar_items: {'Team CTFs': 'TeamCTFs', 'Participating CTFs': 'TeamParticipate', 'Members': 'TeamMembers'},
      selected: 'Team CTFs',
      selectedComponent: 'TeamCTFs',
      defaultImage: require("../../public/assets/default-pfp.webp"),
      isMember: false,
      hasRequested: true,
      getMembersLoaded: false,
      getTeamsLoaded: false,
      hasRequestedLoaded: false,
      requestJoinLoading: false,
      leaveLoading: false,
      request: false,
      ownerLeave: false,
      lastLeave: false,
    }
  },
  computed: {
    teamExists(): boolean {
      return Object.keys(this.team).length !== 0
    },
    avatarImage(): string {
      return this.team.pfp ? this.team.pfp : this.defaultImage
    },
    loaded(): boolean {
      return !this.loading || (this.getTeamsLoaded && this.getMembersLoaded && this.hasRequestedLoaded)
    }
  },
  methods: {
    onRequest() {
      this.request = false
      this.requestJoinLoading = true
      teamApi.requestToJoin(this.team.name, this.user.username).then(val => {
        if (val) {
          this.$root.$emit('alert', {alertType: 'success', alertTitle: 'Request Sent'})
          this.hasRequested = true
        }
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'An error occurred', alertText: 'Please try again later'})
        this.requestJoinLoading = false
      })
    },
    onLeave() {
      if (this.team.owner === this.user.username) {
        if (this.members.length === 1) this.lastLeave = true
        else this.ownerLeave = true
      }
      else this.leaveTeam()
    },
    onOwnerLeave(member: string) {
      this.ownerLeave = false
      this.leaveLoading = true
      // Transfer ownership to member
      teamApi.transferOwner(this.team.name, member).then(val => {
        if (val) this.leaveTeam()
        else {
          this.$root.$emit('alert', {alertType: 'error', alertTitle: 'An error occurred', alertText: 'Couldn\'t transfer ownership'})
          this.leaveLoading = false
        }
      })
    },
    leaveTeam() {
      this.leaveLoading = true;
      this.lastLeave = false
      teamApi.leave(this.team.name).then(val => {
        if (val) {
          this.$emit('leave')
          this.$root.$emit('alert', {alertType: 'success', alertTitle: 'Successfully left team'})
        }
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'An error occurred', alertText: 'Please try again later'})
        this.leaveLoading = false;
      })
    }
  },
  created() {
    const name = this.$route.params.teamName;
    if (name) {
      onLogin(async (err: AlertData, user: BasicUser) => {
        if (err) {
          this.$emit('alert', err);
          this.loading = false;
        }
        else {
          teamApi.getTeam(name).then(team => {
            if (Object.keys(team).length !== 0) {
              Object.assign(this.team, team);

              if (Object.keys(user).length !== 0) {
                Object.assign(this.user, user)

                teamApi.getMembers(name).then(members => {
                  this.isMember = members.includes(this.user.username)
                  this.members = members
                  this.getMembersLoaded = true
                })

                teamApi.hasRequested(name, this.user.username).then(val => {
                  this.hasRequested = val
                  this.hasRequestedLoaded = true;
                })
              }
              else {
                this.getMembersLoaded = true;
                this.hasRequestedLoaded = true
              }
            }
            else {
              this.team = {} as Team
              this.loading = false
            }
            this.getTeamsLoaded = true
          })
        }
      })
    }
  }
});
