
import Vue, {PropType} from "vue";
import {Team} from "../../../cs6131-backend/types/teamTypes";
import TeamSearchCard from "@/components/SearchCards/TeamSearchCard.vue";

export default Vue.extend({
  name: "TeamGrid",
  components: {TeamSearchCard},
  props: {
    teamsProp: Array as PropType<Team[]>,
    isInvited: Boolean
  },
  data() {
    return {
      teams: this.teamsProp
    }
  },
  methods: {
    onReject(teamName: string) {
      this.teams = this.teams.filter(team => team.name !== teamName)
    },
    onAccept(teamName: string) {
      this.teams = this.teams.filter(team => team.name !== teamName)
      this.$root.$emit('alert', {alertType: 'success', alertTitle: `Successfully joined ${teamName}`})
    }
  },
})
