
import Vue from "vue";
import {Team} from "../../../cs6131-backend/types/teamTypes";
import {BasicUser} from "../../../cs6131-backend/types/userTypes";
import {CTF} from "../../../cs6131-backend/types/ctfTypes";
import CTFSearchCard from "@/components/SearchCards/CTFSearchCard.vue";
import * as ctfApi from "@/api/ctfApi"

export default Vue.extend({
  name: "TeamParticipate",
  props: {
    teamProp: Team,
    'user': BasicUser
  },
  components: {CTFSearchCard},
  data() {
    return {
      team: this.teamProp,
      ctfs: [] as Array<CTF>,
      loaded: false
    }
  },
  computed: {
    ctfsSorted(): Array<CTF> {
      return [...this.ctfs].sort((a,b) => a.name.localeCompare(b.name))
    }
  },
  created() {
    ctfApi.getCompetingCTFs(this.team.name).then(data => {
      this.ctfs = data;
      this.loaded = true;
    })
  }
});
