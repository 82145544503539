
import Vue from "vue";
import {onLogin} from "@/api/userApi";
import {AlertData} from "@/schemas/alertData";
import {BasicUser, User} from "../../cs6131-backend/types/userTypes";
import {getCTF} from "@/api/ctfApi";
import {CTF} from "../../cs6131-backend/types/ctfTypes";
import {Team} from "../../cs6131-backend/types/teamTypes";
import CTFChallenges from "@/components/CTF/CTFChallenges.vue";
import Leaderboard from "@/components/CTF/Leaderboard.vue";

export default Vue.extend({
  name: "UserCTF",
  components: {
    'Challenges': CTFChallenges,
    'Leaderboard': Leaderboard
  },
  data() {
    return {
      user: new User(),
      team: new Team(),
      ctf: new CTF(),
      loading: true,
      selected: 'Challenges',
      toolbar_items: ['Challenges', 'Leaderboard']
    }
  },
  computed: {
    ctfExists(): boolean {
      return Object.keys(this.ctf).length !== 0
    }
  },
  created() {
    const id = this.$route.params.id
    if (id) {
      onLogin(async (err: AlertData, user: BasicUser) => {
        if (err) {
          this.$emit('alert', err);
          this.loading = false;
        }
        if (Object.keys(user).length !== 0) Object.assign(this.user, user)
        getCTF(id).then(ctf => {
          if(Object.keys(ctf).length !== 0) Object.assign(this.ctf, ctf)
          else this.ctf = {} as CTF
          this.loading = false;
        })
      })
    }
  }
})
