
import Vue, {PropType} from "vue"

export default Vue.extend({
  name: "OwnerLeave",
  props: {
    members: Array as PropType<string[]>
  },
  data() {
    return {
      leaveDisabled: false,
      selected: this.members[0]
    }
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
    onLeave() {
      this.leaveDisabled = true
      this.$emit('leave', this.selected)
    }
  },
  mounted() {
    this.leaveDisabled = false
  }
})
