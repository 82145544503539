
import Vue from "vue";
import * as noteApi from "@/api/noteApi"
import {onLogin} from "@/api/userApi";
import {AlertData} from "@/schemas/alertData";
import {BasicUser} from "../../cs6131-backend/types/userTypes";
import {Writeup} from "../../cs6131-backend/types/writeupTypes";
import WriteupGrid from "@/components/WriteupGrid.vue";

export default Vue.extend({
  name: "WriteUps",
  components: {WriteupGrid},
  data() {
    return {
      user: new BasicUser(),
      writeups: [] as Array<Writeup>,
      create: false,
      loaded: false,
    }
  },
  methods: {
    onClick() {
      window.location.href = '/writeups/create'
    }
  },
  mounted() {
    onLogin((err: AlertData, user: BasicUser) => {
      if (err) this.$root.$emit('alert', err)
      if (Object.keys(user).length === 0) this.$emit("open-login");
      else {
        Object.assign(this.user, user)
        noteApi.getUserWriteups(this.user.username).then(writeups => {
          this.writeups = writeups
          this.loaded = true
        })
      }
    })
  }
})
