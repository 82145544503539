
import Vue, {PropType} from "vue"
import {Team} from "../../../cs6131-backend/types/teamTypes";

export default Vue.extend({
  name: "InviteDialog",
  props: {
    teams: Array as PropType<Team[]>
  },
  data() {
    return {
      inviteDisabled: false,
      selected: this.teams[0].name
    }
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
    onInvite() {
      this.$emit('invite', this.selected)
    }
  }
})
