
import Vue from "vue";
import {BasicUser} from "../../cs6131-backend/types/userTypes";
import UserTeamCTF from "@/components/UserCTFs/UserTeamCTF.vue"
import UserParticipatingCTF from "@/components/UserCTFs/UserParticipatingCTF.vue"
import {onLogin} from "@/api/userApi";
import {AlertData} from "@/schemas/alertData";

export default Vue.extend({
  name: "CTF",
  components: {
    'Team CTFs': UserTeamCTF,
    'Participating CTFs': UserParticipatingCTF
  },
  data() {
    return {
      user: new BasicUser(),
      toolbar_items: ['Team CTFs', 'Participating CTFs'],
      selected: 'Team CTFs',
      loaded: false
    }
  },
  mounted() {
    onLogin(async (err: AlertData, user: BasicUser) => {
      if (err) {
        this.$emit('alert', err);
        this.loaded = false;
      }
      if (Object.keys(user).length !== 0) {
        Object.assign(this.user, user)
        this.loaded = true
      }
    })
  }
})
