
import Vue from "vue";
import {Team} from "../../../cs6131-backend/types/teamTypes";
import TeamSearchCard from "@/components/SearchCards/TeamSearchCard.vue";
import CreateTeamDialog from "@/components/Dialogs/CreateTeamDialog.vue";
import InviteDialog from "@/components/Dialogs/InviteDialog.vue";
import {BasicUser} from "../../../cs6131-backend/types/userTypes";
import {getCookie} from "typescript-cookie";
import * as teamApi from "@/api/teamApi"

export default Vue.extend({
  name: "MyTeams",
  props: {
    'user': BasicUser,
  },
  components: {CreateTeamDialog, TeamSearchCard, InviteDialog},
  data() {
    return {
      teams: [] as Array<Team>,
      loggedInUserTeams: [] as Array<Team>,
      create: false,
      loaded: false,
      isPublic: true,
      invite: false,
      inviteLoading: false,
    }
  },
  computed: {
    userExists(): boolean {
      return Boolean(getCookie('token'))
    }
  },
  methods: {
    onCreate(team: Team) {
      if (this.teams.length === 0) this.teams.push(team)
      else {
        for (const key in this.teams) {
          if (this.teams[key].name.localeCompare(team.name) > 0) {
            this.teams.splice(parseInt(key), 0, team)
            break
          }
          if (parseInt(key) === this.teams.length - 1) this.teams.push(team)
        }
      }
    },
    onInvite(team: string) {
      this.inviteLoading = true
      this.invite = false

      teamApi.inviteToTeam(team, this.user?.username).then(val => {
        if (val) {
          this.$root.$emit('alert', {alertType: 'success', alertTitle: 'Invite Sent'})
          this.loggedInUserTeams = this.loggedInUserTeams.filter(userteam => userteam.name !== team)
        }
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'An error occurred', alertText: 'Please try again later'})
        this.inviteLoading = false
      })
    }
  },
  created() {
    teamApi.getUserTeams(this.user.username).then(res => {
      if(res.status === 200) {
        res.json().then(data => {
          this.teams = data.teams
          this.isPublic = data.isPublic

          if (data.isPublic && this.userExists) {
            teamApi.getNotInvitedTeams(this.user?.username).then(teams => {
              this.loggedInUserTeams = teams
              this.loaded = true
            })
          }
          else this.loaded = true
        })
      }
    });
  }
});
