
import Vue from "vue";
import {MemberStat, Team} from "../../../cs6131-backend/types/teamTypes";
import {BasicUser} from "../../../cs6131-backend/types/userTypes";
import * as teamApi from "@/api/teamApi"
import MemberInfoDialog from "@/components/Dialogs/MemberInfoDialog.vue";

export default Vue.extend({
  name: "TeamMembers",
  components: {MemberInfoDialog},
  props: {
    teamProp: Team,
    user: BasicUser,
  },
  data() {
    return {
      team: this.teamProp,
      getMemberStatsLoaded: false,
      getRequestedUsersLoaded: false,
      getInvitedUsersLoaded: false,
      memberStats: [] as Array<MemberStat>,
      headers: [
        {text: 'Member', value: 'username'},
        {text: 'No. Competing', value: 'num_competing'},
        {text: 'No. Solves', value: 'num_solves'},
        {text: 'Total Points', value: 'total_points'}
      ],
      items: ['Requesting', 'Invited'],
      selected: 'Requesting',
      requestedUsers: [] as Array<string>,
      invitedUsers: [] as Array<string>,
      acceptRequestLoading: [] as Array<string>,
      denyRequestOrInviteLoading: [] as Array<string>,
      selectedMember: '',
      memberInfo: false
    }
  },
  computed: {
    isOwner(): boolean {
      return this.team?.owner === this.user?.username
    },
    loaded(): boolean {
      return this.isOwner ? (this.getInvitedUsersLoaded && this.getRequestedUsersLoaded) : this.getMemberStatsLoaded
    },
    users(): Array<string> {
      return this.selected === 'Requesting' ? this.requestedUsers : this.invitedUsers
    }
  },
  methods: {
    acceptRequest(username: string) {
      this.acceptRequestLoading.push(username)

      teamApi.acceptRequest(this.team?.name, username).then(val => {
        if (val) {
          this.requestedUsers = this.requestedUsers.filter(name => name !== username)
          this.memberStats.push({username: username, num_competing: 0, num_solves: 0, total_points: '0'})
        }
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'Error accepting request', alertText: 'Please try again later'})

        this.acceptRequestLoading = this.acceptRequestLoading.filter(name => name !== username)
      })
    },
    denyRequestOrInvite(username: string) {
      if (this.selected === 'Requesting') this.denyRequest(username)
      if (this.selected === 'Invited') this.removeInvite(username)
    },
    denyRequest(username: string) {
      this.denyRequestOrInviteLoading.push(username)
      teamApi.denyRequest(this.team?.name, username).then(val => {
        if (val) this.requestedUsers = this.requestedUsers.filter(name => name !== username)
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'Error denying request', alertText: 'Please try again later'})

        this.denyRequestOrInviteLoading = this.denyRequestOrInviteLoading.filter(name => name !== username)
      })
    },
    removeInvite(username: string) {
      this.denyRequestOrInviteLoading.push(username)
      teamApi.removeInvite(this.team?.name, username).then(val => {
        if (val) this.invitedUsers = this.invitedUsers.filter(name => name !== username)
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'Error removing invite', alertText: 'Please try again later'})

        this.denyRequestOrInviteLoading = this.denyRequestOrInviteLoading.filter(name => name !== username)
      })
    },
    onClick(member: MemberStat) {
      if (this.isOwner && member.username !== this.team?.owner) {
        this.selectedMember = member.username
        this.memberInfo = true
      }
    },
    onKickUser(username: string) {
      this.memberStats = this.memberStats.filter(member => member.username !== username)
    }
  },
  created() {
    teamApi.getMemberStats(this.team.name).then(data => {
      this.memberStats = data
      if (this.isOwner) {
        teamApi.getRequestedUsers(this.team.name).then(users => {
          this.requestedUsers = users.map(user => user.username)
          this.getRequestedUsersLoaded = true
        })
        teamApi.getInvitedUsers(this.team.name).then(users => {
          this.invitedUsers = users.map(user => user.username)
          this.getInvitedUsersLoaded = true
        })
      } else this.getMemberStatsLoaded = true
    }).catch(res => {
      this.$root.$emit('alert', {alertType: 'error', alertTitle: `${res.status} Error`, alertText: res.statusText})
    })
  }
});
