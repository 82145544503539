
import Vue from "vue";
import * as userApi from "@/api/userApi"
import {removeCookie} from "typescript-cookie";

export default Vue.extend({
  name: "DeleteAccountDialog",
  props: ["username"],
  data() {
    return {
      deleteUsername: '',
      loading: false
    }
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
      this.deleteUsername = ''
      this.loading = false
    },
    deleteAccount() {
      this.loading = true;
      userApi.deleteAccount(this.username).then(val => {
        if (val) {
          removeCookie('token', {path:'/'})
          this.$router.push({ path: '/home' })
          this.$root.$emit('account-delete')
        }
        else {
          this.onClose()
          this.$root.$emit('alert', {alertType: 'error', alertTitle: 'Error deleting account', alertText: "Please try again later"})
        }
      })
    }
  }
})
