
import Vue from "vue";
import TeamGrid from "@/components/Teams/TeamGrid.vue";
import {BasicUser} from "../../../cs6131-backend/types/userTypes";
import {Team} from "../../../cs6131-backend/types/teamTypes";
import * as teamApi from "@/api/teamApi"

export default Vue.extend({
  name: "InviteRequestTeams",
  components: {TeamGrid},
  props: {
    user: BasicUser,
    request: Boolean,
    invite: Boolean
  },
  data() {
    return {
      teams: [] as Array<Team>,
      loaded: false
    }
  },
  computed: {
    hasTeams(): boolean {
      return this.teams.length !== 0
    }
  },
  created() {
    teamApi.getRequestedOrInvitedTeams(this.request ? 'getRequestedTeams' : 'getInvitedTeams', this.user?.username).then(teams => {
      this.teams = teams
      this.loaded = true
    })
  }
});
