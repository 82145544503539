
import Vue, {PropType} from "vue";
import {Team} from "../../../cs6131-backend/types/teamTypes";

export default Vue.extend({
  name: "CompeteDialog",
  props: {
    teams: Array as PropType<Array<Team>>
  },
  data() {
    return {
      loading: false,
      competingTeam: this.teams[0].name,
      teamNames: this.teams?.map(team => team.name)
    }
  },
  computed: {
    clean(): boolean {
      return this.competingTeam !== ''
    }
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
    compete() {
      this.$emit('compete', this.competingTeam)
      this.onClose()
    }
  }
})
