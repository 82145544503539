
import Vue from "vue";
import {onLogin} from "@/api/userApi";
import {BasicUser} from "../../cs6131-backend/types/userTypes";
import {AlertData} from "@/schemas/alertData";

export default Vue.extend({
  data() {
    return {
      user: {} as BasicUser,
    }
  },
  computed: {
    userExists(): boolean {
      return Object.keys(this.user).length !== 0;
    }
  },
  methods: {
    registerDialog() {
      this.$emit("open-register")
    }
  },
  mounted() {
    onLogin((_: AlertData, user: BasicUser) => {
      if (user) this.user = user;
      else this.user = {} as BasicUser
    })
  }
})
