
import Vue from "vue";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import {onLogin} from "@/api/userApi";
import {AlertData} from "@/schemas/alertData";
import {BasicUser} from "../../cs6131-backend/types/userTypes";
import {BasicWriteup} from "../../cs6131-backend/types/writeupTypes";
import * as noteApi from "@/api/noteApi"

Vue.use(mavonEditor)
export default Vue.extend({
  name: "CreateWriteup",
  data() {
    return {
      user: {} as BasicUser,
      value: '',
      title: '',
      loaded: false,
      loggedIn: false,
      error: '',
      createLoading: false,
      isPublic: true,
    }
  },
  computed: {
    userExists(): Boolean {
      return Object.keys(this.user).length !== 0
    },
    clean(): Boolean {
      return this.value.trim() !== ''
    }
  },
  methods: {
    onClickCreate() {
      noteApi.validateWriteupTitle(this.title, (err: string) => {
        if (err) this.setError(err)
        else this.createWriteup()
      })
    },
    setError(error: string) {
      this.error = error
      setTimeout(() => this.error = '', 3000)
      return;
    },
    createWriteup() {
      this.createLoading = true;

      const writeup = Object.assign(new BasicWriteup(), {
        title: this.title,
        content: this.value,
        public: this.isPublic
      } as BasicWriteup)

      noteApi.createWriteup(writeup).then(writeup => {
        window.location.href = `/writeups/${writeup.id}`
      }).catch(res => {
        this.$root.$emit('alert', {alertType: 'error', alertTitle: `${res.status} Error`, alertText: res.statusText})
        this.createLoading = false
      })
    }
  },
  mounted() {
    onLogin((err: AlertData, user: BasicUser) => {
      if (err) this.$root.$emit('alert', err)
      if (Object.keys(user).length === 0) this.$emit("open-login");
      else this.user = user
      this.loaded = true;
    })
  }
})
