
import Vue from "vue";

export default Vue.extend({
  name: "Rating",
  data:() => ({
    value: 0
  }),
  methods: {
    resetValue() {
      this.value = 0
    }
  },
  created() {
    this.$parent?.$on('reset', this.resetValue)
  }
});
