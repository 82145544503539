
import Vue from "vue";
import {CTF} from "../../../cs6131-backend/types/ctfTypes";
import {BasicUser} from "../../../cs6131-backend/types/userTypes";
import {BasicChallenge} from "../../../cs6131-backend/types/chalTypes";
import * as ctfApi from "@/api/ctfApi";
import * as teamApi from "@/api/teamApi"
import CreateChallengeDialog from "@/components/Dialogs/CreateChallengeDialog.vue";
import ChallengeSearchCard from "@/components/SearchCards/ChallengeSearchCard.vue";
import {getFromLocalStorage, setLocalStorage} from "@/api/api";
import CompeteDialog from "@/components/Dialogs/CompeteDialog.vue";
import {Team} from "../../../cs6131-backend/types/teamTypes";

export default Vue.extend({
  name: "CTFChallenges",
  components: {CompeteDialog, CreateChallengeDialog, ChallengeSearchCard},
  props: {
    'ctf': CTF,
    'user': BasicUser
  },
  data() {
    return {
      create: false,
      loaded: false,
      challenges: [] as Array<BasicChallenge>,
      teams: [] as Array<Team>,
      search: '',
      orderBy: 'Category',
      orderItems: ['Category', 'Difficulty'],
      sortBy: 'Points',
      sortItems: ['Points', 'Name'],
      panelModel: [] as Array<number>,
      ascending: true,
      canCreate: false,
      canView: false,
      compete: false
    }
  },
  computed: {
    searchedChals(): Array<BasicChallenge> {
      return [...this.challenges].filter(chal => chal.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    sortedChals(): Array<BasicChallenge> {
      let sortedChals = [] as Array<BasicChallenge>;
      if (this.sortBy === 'Name') {
        sortedChals = [...this.searchedChals].sort((a,b) => {
          return a.name.localeCompare(b.name)
        })
      }
      if (this.sortBy === 'Points') {
        sortedChals = [...this.searchedChals].sort((a,b) => {
          return a.points - b.points
        })
      }

      if (!this.ascending) sortedChals.reverse()

      return sortedChals
    },
    expansionPanelColor(): string {
      return this.$vuetify.theme.dark ? '#121212' : 'white'
    },
    orderPanels(): Array<string> {
      // This is to preserve the order of difficulties
      const cats = ['Web', 'Crypto', 'Forensics', 'Misc', 'OSINT', 'Reverse Engineering', 'Pwn'].sort()
      const diffs = ['Easy', 'Medium', 'Hard', 'Insane']

      for (const key in this.challenges) {
        const chal = this.challenges[key]

        if (chal.category && !cats.includes(chal.category)) cats.push(chal.category);
        if (chal.difficulty && !diffs.includes(chal.difficulty)) diffs.push(chal.difficulty)
      }

      // Use this instead of ternary in case need to add more in future
      const panels = {
        'Category': cats,
        'Difficulty': diffs,
      } as Record<string,Array<string>>

      return panels[this.orderBy]
    },
    orderedChals(): Record<string,Array<BasicChallenge>> {
      const orderedChals = {} as Record<string, Array<BasicChallenge>>
      this.orderPanels.forEach(panel => {
        if (this.orderBy === 'Category') orderedChals[panel] = this.sortedChals.filter(chal => chal.category === panel)
        if (this.orderBy === 'Difficulty') orderedChals[panel] = this.sortedChals.filter(chal => chal.difficulty === panel)
      })

      return orderedChals
    },
    canCompete(): boolean {
      return this.teams.length > 0
    }
  },
  methods: {
    onCompete(teamName: string) {
      ctfApi.compete(this.ctf?.id, teamName).then(val => {
        if (val) {
          this.canView = true;
          this.loaded = false;
          this.getChals()
        }
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'Error competing in CTF', alertText: 'Please try again later'})
      })
    },
    getChals() {
      ctfApi.getCTFChals(this.ctf.id).then(res => {
        if (res.status === 200) res.json().then(data => {
          if (data.challenges.length > 0) {
            this.challenges = data.challenges as Array<BasicChallenge>
            setLocalStorage(`${this.ctf?.id}_chals`, JSON.stringify(data.challenges))
          }

          this.canView = data.canView
          this.canCreate = data.isMember
          this.loaded = true

          if (!data.canView) {
            teamApi.getUserTeams(this.user?.username).then(res => {
              if (res.status === 200) {
                res.json().then(data => {
                  this.teams = data.teams
                })
              }
            })
          }
        })
        else {
          this.$root.$emit('alert', {alertType: 'error', alertTitle: `Error ${res.status}`, alertText: res.statusText})
        }
      })
    }
  },
  created() {
    const chals = getFromLocalStorage(`${this.ctf?.id}_chals`)
    if (chals) {
      this.challenges = chals;
      this.loaded = true;
      this.canView = true
    }

    this.getChals()
  }
});
