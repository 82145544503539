
import Vue from "vue"

export default Vue.extend({
  name: "GenericWarningDialog",
  props: ['title', 'text', 'acceptText', 'acceptColor'],
  data() {
    return {
      disabled: false
    }
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
    onRequest() {
      this.disabled = true
      this.$emit('accept')
    }
  }
})
