
import Vue from "vue";
import UserSearchCard from "@/components/SearchCards/UserSearchCard.vue";
import TeamSearchCard from "@/components/SearchCards/TeamSearchCard.vue";
import CTFSearchCard from "@/components/SearchCards/CTFSearchCard.vue";
import WriteUpSearchCard from "@/components/SearchCards/WriteUpSearchCard.vue";
import {searchByPage} from "@/api/api";

export default Vue.extend({
  name: "Search",
  components: {UserSearchCard, TeamSearchCard, CTFSearchCard, WriteUpSearchCard},
  data() {
    return {
      search: '',
      searchQueries: [
        {
          name: "Users",
          query: "user",
          component: 'UserSearchCard'
        },
        {
          name: "Teams",
          query: "team",
          component: 'TeamSearchCard'
        },
        {
          name: "CTFs",
          query: "ctf",
          component: 'CTFSearchCard'
        },
        {
          name: "Write Ups",
          query: "writeup",
          component: 'WriteUpSearchCard'
        }
      ],
      selected: 0,
      selectedQuery: "Users",
      windowWidth: innerWidth,
      loaded: false,
      searchResults: [],
      searchDelay: 0,
      pageNum: 1,
      totalPages: 1
    }
  },
  computed: {
    isMobile(): boolean {
      return this.windowWidth <= 480
    },
    isNotMobile(): boolean {
      return !this.isMobile
    },
    currentComponent(): string {
      if (this.isMobile) {
        return this.searchQueries.find(obj => obj.name === this.selectedQuery)!.component
      }

      return this.searchQueries[this.selected].component
    },
    selectedTable(): string {
      if (this.isMobile) {
        return this.searchQueries.find(obj => obj.name === this.selectedQuery)!.query
      }

      return this.searchQueries[this.selected].query
    }
  },
  methods: {
    onSearch() {
      this.searchDelay += 1
      this.loaded = false;
      this.totalPages = 1
      setTimeout(() => {
        this.searchDelay -= 1
        if (this.searchDelay === 0 && this.search !== '') {
          searchByPage(this.selectedTable, this.pageNum, this.search).then(res => {
            if (res.status === 200) {
              res.json().then(data => {
                this.searchResults = data.results
                this.totalPages = data.numPages
                this.loaded = true;
              })
            } else {
              this.$root.$emit('alert', {
                alertType: 'error',
                alertTitle: `${res.status} Error`,
                alertText: res.statusText
              })
              this.loaded = true;
            }
          })
        }
      }, 1000)
    },
    nextPage() {
      this.pageNum++;
      this.onSearch()
    },
    previousPage() {
      this.pageNum--;
      this.onSearch()
    },
    goToPage(pageNum: number) {
      this.pageNum = pageNum
      this.onSearch()
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    search(newSearch: string, oldSearch: string) {
      if (newSearch !== oldSearch && newSearch.trim() !== '') this.onSearch()
    }
  }
})
