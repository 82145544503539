
import Vue from "vue";
import * as noteApi from "@/api/noteApi"
import {Writeup} from "../../cs6131-backend/types/writeupTypes";
import {AlertData} from "@/schemas/alertData";
import {BasicUser} from "../../cs6131-backend/types/userTypes";
import {onLogin} from "@/api/userApi";

export default Vue.extend({
  name: "Writeup",
  data() {
    return {
      noteid: '',
      hasAccess: false,
      writeup: {} as Writeup,
      authors: [] as Array<string>,
      user: {} as BasicUser,
      value: '',
      getWriteupLoaded: false,
      exists: false,
      edit: false,
      title: '',
      error: '',
      loginLoaded: false,
      isPublic: true,
      saveLoading: false,
    }
  },
  computed: {
    loaded(): Boolean {
      return this.loginLoaded && this.getWriteupLoaded
    }
  },
  methods: {
    onEditClick() {
      this.edit = true
    },
    onSaveClick() {
      this.saveLoading = true

      const newWriteup = Object.assign(new Writeup(), this.writeup)
      newWriteup.title = this.title
      newWriteup.public = Boolean(this.isPublic)
      newWriteup.content = this.value

      noteApi.updateWriteup(newWriteup).then(res => {
        if (res.status === 200) {
          this.writeup = newWriteup
          this.$root.$emit('alert', {alertType: 'success', alertTitle: 'Successfully edited writeup'})
        }
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: `${res.status} Error`, alertText: `${res.statusText}`})
        this.saveLoading = false
        this.edit = false
      })
    },
    setError(error: string) {
      this.error = error
      setTimeout(() => this.error = '', 3000)
      return;
    },
  },
  mounted() {
    this.noteid = this.$route.params.id
    onLogin((err: AlertData, user: BasicUser) => {
      if (err) this.$root.$emit('alert', err)
      if (Object.keys(user).length !== 0) this.user = user
      this.loginLoaded = true;
    })

    noteApi.getWriteup(this.noteid).then(res => {
      if (res.status === 200) res.json().then(data => {
        this.exists = true
        this.hasAccess = data.hasAccess
        if (this.hasAccess) {
          this.writeup = data.writeup
          this.title = this.writeup.title
          this.value = this.writeup.content
          this.authors = data.authors
          this.isPublic = this.writeup.public
        }

        this.getWriteupLoaded = true
      })
      else {
        if (res.status === 404) this.exists = false
        this.$root.$emit('alert', {alertType: 'error', alertTitle: `${res.status} Error`, alertText: `${res.statusText}`})
        this.getWriteupLoaded = true
      }
    })
  }
})
