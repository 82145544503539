
import Vue from "vue";
import * as teamApi from "@/api/teamApi"

export default Vue.extend({
  name: "TeamSearchCard",
  props: ['item', 'isInvited'],
  data() {
    return {
      rejectInviteLoading: false,
      acceptInviteLoading: false
    }
  },
  methods: {
    rejectInvite() {
      this.rejectInviteLoading = true
      teamApi.rejectInvite(this.item.name).then(val => {
        if (val) this.$emit('reject-invite', this.item.name)
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'An error occurred', alertText: 'Please try again later'})
        this.rejectInviteLoading = false
      })
    },
    acceptInvite() {
      this.acceptInviteLoading = true
      teamApi.acceptInvite(this.item.name).then(val => {
        if (val) this.$emit('accept-invite', this.item.name)
        else this.$root.$emit('alert', {alertType: 'error', alertTitle: 'An error occurred', alertText: 'Please try again later'})
        this.acceptInviteLoading = false
      })
    }
  }
});
