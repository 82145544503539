
import Vue from "vue"
import RegisterDialog from "@/components/Dialogs/RegisterDialog.vue";
import LoginDialog from "@/components/Dialogs/LoginDialog.vue";
import Alert from "@/components/Alerts/Alert.vue";
import {BasicUser} from "../cs6131-backend/types/userTypes";
import {login, onLogin} from "@/api/userApi";
import {removeCookie, setCookie} from "typescript-cookie";
import {AlertData} from "@/schemas/alertData";


if (process.env.NODE_ENV === 'production') {
  console.log("Running in production")
  Vue.prototype.$apilink = 'https://mug1wara26.app.wern.cc';
} else {
  console.log("Running locally")
  Vue.prototype.$apilink = 'http://localhost:3000'
}


export default Vue.extend({
  name: 'App',
  components: {Alert: Alert, LoginDialog, RegisterDialog},
  data() {
    return {
      user: {} as BasicUser,
      alertTitle: "",
      alertText: "",
      alertShown: "",
      drawer: false,
      register: false,
      login: false,
      windowWidth: window.innerWidth,
      reRender: 0,
    }
  },
  computed: {
    userExists(): boolean {
      return Object.keys(this.user).length !== 0;
    },
    routes(): Array<{
      name: string;
      route: string;
      icon: string;
    }> {
      return [
        {
          name: "Home",
          route: "/",
          icon: "mdi-home",
        },
        {
          name: "Search",
          route: "/search",
          icon: "mdi-magnify",
        },
        {
          name: "Teams",
          route: "/teams",
          icon: "mdi-account-group",
        },
        {
          name: "CTFs",
          route: "/ctfs",
          icon: "mdi-flag",
        },
        {
          name: "Write Ups",
          route: "/writeups",
          icon: "mdi-file-document",
        },
      ];
    },
    isMobile(): boolean {
      return this.windowWidth <= 480
    },
    isNotMobile(): boolean {
      return this.windowWidth > 480
    },
    alertWidth(): string {
      return (this.windowWidth * 0.3 >= 300) ? '30%' : '100%'
    }
  },
  methods: {
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      window.localStorage.setItem("dark", this.$vuetify.theme.dark.toString())
    },
    showAlert(alertType: string, alertTitle: string, alertText: string) {
      this.alertTitle = alertTitle;
      this.alertText = alertText;
      this.alertShown = alertType;
      setTimeout(() => {
        this.alertShown = ""
      }, 3000)
    },
    onRegister(username: string, password: string, displayName: string) {
      login(username, password).then(res => {
        setCookie('token', res, {sameSite: "lax", path: '/'});
        this.onLogin();
        this.showAlert('success', 'Register Successful', `Welcome ${displayName}`)
      }).catch(() => {
        this.showAlert("error", "Login Error", "Please try logging in again")
      })
    },
    onLogin() {
      onLogin((err: AlertData, user: BasicUser) => {
        if (err) this.showAlert(err.alertType, err.alertTitle, err.alertText);
        else {
          this.user = user;
          this.reRender++; // Re-renders router view so that user login is reflected.
        }
      })
    },
    logout() {
      removeCookie('token', {path: '/'})
      this.user = {} as BasicUser
      this.$router.push({ path: '/home' })
      this.reRender++
    },
    onLeave() {
      this.$router.push({ path: '/teams' })
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    }
    onLogin((err: AlertData, user: BasicUser) => {
      if (err) this.showAlert(err.alertType, err.alertTitle, err.alertText)
      else this.user = user;
    })
  },
  created() {
    this.$root.$on('alert', (alertData: AlertData) => {
      this.showAlert(alertData.alertType, alertData.alertTitle, alertData.alertText)
    })
    this.$root.$on('account-delete', () => {
      this.showAlert('success', 'Account deleted successfully', '')
      this.user = {} as BasicUser
      this.reRender++
    })
    this.$root.$on('re-render', () => {
      this.reRender++
    })
  },
});
