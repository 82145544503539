
import Vue from "vue";
import rating from "@/components/Rating.vue";

export default Vue.extend({
  components: {
    rating
  },
  data:()=> ({
    alert: false,
    value: ''
  }),
  methods: {
    submitForm() {
      this.value = ''
      this.$emit('reset')
      this.alert=true
      setTimeout(() => {this.alert = false}, 2000)
    }
  }
})
