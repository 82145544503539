
import Vue from "vue";
import {BasicUser} from "../../../cs6131-backend/types/userTypes";
import * as ctfApi from "@/api/ctfApi"
import {ctfCompetingTeam} from "../../../cs6131-backend/types/ctfTypes";
import CTFSearchCard from "@/components/SearchCards/CTFSearchCard.vue";

export default Vue.extend({
  name: "UserParticipatingCTF",
  components: {CTFSearchCard},
  props: {
    user: BasicUser
  },
  data() {
    return {
      ctfs: [] as Array<ctfCompetingTeam>,
      loaded: false
    }
  },
  mounted() {
    ctfApi.getAllCompetingCTFs().then(ctfs => {
      this.ctfs = ctfs
      this.loaded = true
    }).catch(res => {
      this.$root.$emit('alert', {alertType: 'error', alertTitle: `${res.status} Error`, alertText: `${res.statusText}`})
    })
  }
})
