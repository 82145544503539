
import Vue from "vue";
import {BasicChallenge} from "../../cs6131-backend/types/chalTypes";
import {getFromLocalStorage} from "@/api/api";
import * as chalApi from "@/api/chalApi"
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.use(mavonEditor)
export default Vue.extend({
  name: "Challenge",
  data() {
    return {
      name: '',
      ctfid: '',
      loaded: false,
      chal: {} as BasicChallenge,
      isPublic: false,
      panel: 0,
      selected: 0,
      items: ['Note 1', 'Note 2', 'Note 3'],
      value: '',
      flag: '',
      loading: false,
      flagRules: [] as Array<Function>,
      solved: true
    }
  },
  computed: {
    chalExists(): boolean {
      return Object.keys(this.chal).length !== 0
    },
    clean() : boolean {
      return this.flag !== '' && !this.solved
    },
  },
  methods: {
    submitFlag() {
      this.loading = true;

      this.flagRules = [
        (v: string | null) => v && v.length <= 256 || 'Max 256 characters',
        (v: string | null) => v && v.length >= 3 || 'Min 3 characters',
        (v: string | null) => v && /^[A-Za-z0-9_@./#&+\-!?{}()]*$/.test(v) || 'Flag can only contain alphanumeric and special characters',
      ]


      this.$nextTick(() => {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          chalApi.solve(this.ctfid, this.name, this.flag).then(val => {
            this.flagRules = []
            this.loading = false;
            this.solved = val;
            this.flag = ''
            if (val) this.$root.$emit('alert', {alertType: 'success', alertTitle: `${this.name} solved`})
            else this.$root.$emit('alert', {alertType: 'error', alertTitle: `Wrong flag`})
          })
        }
        else this.loading = false
      })
    }
  },
  created() {
    this.name = this.$route.params.name
    this.ctfid = this.$route.params.id

    const chals = getFromLocalStorage(`${this.ctfid}_chals`) as Array<BasicChallenge>
    if (chals) {
      this.chal = chals.find(chal => chal.name === this.name) || {} as BasicChallenge
      this.loaded = true
    }

    chalApi.getChalUserData(this.ctfid, this.name).then(data => {
      if (Object.keys(data).length !== 0) {
        this.chal = data.challenge
        this.isPublic = data.isPublic
        this.solved = data.isSolved
      }

      this.loaded = true;
    })
  },
});
